import React from "react";
import PropTypes from "prop-types";

const ActiveSettingIcon = ({ className }) => {
	return (
		<svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M19.2301 12.3701C19.036 12.0701 18.76 11.7701 18.4023 11.5801C18.1162 11.4401 17.9322 11.2101 17.7687 10.9401C17.2475 10.0801 17.5541 8.95012 18.4228 8.44012C19.4447 7.87012 19.7718 6.60012 19.179 5.61012L18.4943 4.43012C17.9118 3.44012 16.6344 3.09012 15.6226 3.67012C14.7233 4.15012 13.5685 3.83012 13.0473 2.98012C12.8838 2.70012 12.7918 2.40012 12.8122 2.10012C12.8429 1.71012 12.7203 1.34012 12.5363 1.04012C12.1582 0.420122 11.4735 0.00012207 10.7172 0.00012207H9.27627C8.53024 0.0201221 7.84553 0.420122 7.4674 1.04012C7.27323 1.34012 7.16081 1.71012 7.18125 2.10012C7.20169 2.40012 7.10972 2.70012 6.9462 2.98012C6.425 3.83012 5.27019 4.15012 4.38109 3.67012C3.35913 3.09012 2.09191 3.44012 1.49917 4.43012L0.814459 5.61012C0.231943 6.60012 0.55897 7.87012 1.57071 8.44012C2.43937 8.95012 2.74596 10.0801 2.23498 10.9401C2.06125 11.2101 1.87729 11.4401 1.59115 11.5801C1.24368 11.7701 0.937094 12.0701 0.773581 12.3701C0.395456 12.9901 0.415896 13.7701 0.79402 14.4201L1.49917 15.6201C1.87729 16.2601 2.58245 16.6601 3.31825 16.6601C3.66572 16.6601 4.0745 16.5601 4.40153 16.3601C4.65702 16.1901 4.96361 16.1301 5.30085 16.1301C6.31259 16.1301 7.16081 16.9601 7.18125 17.9501C7.18125 19.1001 8.12145 20.0001 9.30692 20.0001H10.6968C11.872 20.0001 12.8122 19.1001 12.8122 17.9501C12.8429 16.9601 13.6911 16.1301 14.7029 16.1301C15.0299 16.1301 15.3365 16.1901 15.6022 16.3601C15.9292 16.5601 16.3278 16.6601 16.6855 16.6601C17.411 16.6601 18.1162 16.2601 18.4943 15.6201L19.2097 14.4201C19.5776 13.7501 19.6083 12.9901 19.2301 12.3701Z" fill="#1A87FF" />
			<path d="M10.0122 12.8302C8.40775 12.8302 7.10986 11.5802 7.10986 10.0102C7.10986 8.44018 8.40775 7.18018 10.0122 7.18018C11.6167 7.18018 12.8839 8.44018 12.8839 10.0102C12.8839 11.5802 11.6167 12.8302 10.0122 12.8302Z" fill="white" />
		</svg>
	);
};

ActiveSettingIcon.propTypes = {
	className: PropTypes.string,
};

ActiveSettingIcon.defaultProps = {
	className: "",
};

export default ActiveSettingIcon;
