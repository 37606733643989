import React from "react";
import PropTypes from "prop-types";

const ActiveGraphIcon = ({ className }) => {
	return (
		<svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.15275 3.55559C8.20368 3.65925 8.23732 3.77027 8.25239 3.8844L8.53082 8.02432L8.66903 10.1051C8.67046 10.3191 8.70403 10.5317 8.76867 10.7361C8.9356 11.1326 9.33716 11.3846 9.77405 11.3671L16.4313 10.9316C16.7196 10.9269 16.998 11.0347 17.2052 11.2313C17.3779 11.3952 17.4894 11.6096 17.5246 11.8402L17.5364 11.9802C17.2609 15.795 14.4592 18.9767 10.6524 19.7981C6.84555 20.6194 2.94186 18.8844 1.06071 15.535C0.518387 14.5619 0.17965 13.4923 0.0643819 12.389C0.0162286 12.0624 -0.00497452 11.7326 0.000979226 11.4026C-0.00496595 7.31279 2.90747 3.77702 6.98433 2.92463C7.47501 2.84822 7.95603 3.10798 8.15275 3.55559Z" fill="#1A87FF" />
			<path d="M10.87 0.000819186C15.4298 0.116827 19.2623 3.39579 19.9999 7.81229L19.9929 7.84488L19.9728 7.89227L19.9756 8.02236C19.9651 8.19471 19.8986 8.36054 19.7839 8.49448C19.6645 8.63401 19.5013 8.72903 19.3215 8.76591L19.2119 8.78095L11.5312 9.27861C11.2757 9.3038 11.0213 9.22142 10.8313 9.05195C10.673 8.91073 10.5717 8.72009 10.5431 8.51468L10.0276 0.845062C10.0186 0.819129 10.0186 0.791015 10.0276 0.765082C10.0346 0.553672 10.1277 0.35384 10.286 0.21023C10.4443 0.0666198 10.6546 -0.00880037 10.87 0.000819186Z" fill="white" />
		</svg>
	);
};

ActiveGraphIcon.propTypes = {
	className: PropTypes.string,
};

ActiveGraphIcon.defaultProps = {
	className: "",
};

export default ActiveGraphIcon;
