import React from "react";
import PropTypes from "prop-types";

const SettingIcon = ({ className }) => {
	return (
		<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.012 14.8302C10.4075 14.8302 9.10962 13.5802 9.10962 12.0102C9.10962 10.4402 10.4075 9.18018 12.012 9.18018C13.6165 9.18018 14.8837 10.4402 14.8837 12.0102C14.8837 13.5802 13.6165 14.8302 12.012 14.8302Z" fill="white" fill-opacity="0.7" />
			<path opacity="0.4" d="M21.23 14.3701C21.0358 14.0701 20.7599 13.7701 20.4022 13.5801C20.1161 13.4401 19.9321 13.2101 19.7686 12.9401C19.2474 12.0801 19.554 10.9501 20.4227 10.4401C21.4446 9.87012 21.7716 8.60012 21.1789 7.61012L20.4942 6.43012C19.9117 5.44012 18.6342 5.09012 17.6225 5.67012C16.7232 6.15012 15.5684 5.83012 15.0472 4.98012C14.8837 4.70012 14.7917 4.40012 14.8121 4.10012C14.8428 3.71012 14.7201 3.34012 14.5362 3.04012C14.1581 2.42012 13.4734 2.00012 12.7171 2.00012H11.2761C10.5301 2.02012 9.8454 2.42012 9.46728 3.04012C9.27311 3.34012 9.16069 3.71012 9.18113 4.10012C9.20157 4.40012 9.10959 4.70012 8.94608 4.98012C8.42488 5.83012 7.27007 6.15012 6.38097 5.67012C5.35901 5.09012 4.09178 5.44012 3.49905 6.43012L2.81434 7.61012C2.23182 8.60012 2.55885 9.87012 3.57059 10.4401C4.43925 10.9501 4.74584 12.0801 4.23486 12.9401C4.06112 13.2101 3.87717 13.4401 3.59102 13.5801C3.24356 13.7701 2.93697 14.0701 2.77346 14.3701C2.39533 14.9901 2.41577 15.7701 2.7939 16.4201L3.49905 17.6201C3.87717 18.2601 4.58232 18.6601 5.31813 18.6601C5.6656 18.6601 6.07438 18.5601 6.40141 18.3601C6.6569 18.1901 6.96348 18.1301 7.30073 18.1301C8.31247 18.1301 9.16069 18.9601 9.18113 19.9501C9.18113 21.1001 10.1213 22.0001 11.3068 22.0001H12.6967C13.8719 22.0001 14.8121 21.1001 14.8121 19.9501C14.8428 18.9601 15.691 18.1301 16.7027 18.1301C17.0298 18.1301 17.3364 18.1901 17.6021 18.3601C17.9291 18.5601 18.3277 18.6601 18.6853 18.6601C19.4109 18.6601 20.1161 18.2601 20.4942 17.6201L21.2096 16.4201C21.5775 15.7501 21.6081 14.9901 21.23 14.3701Z" fill="white" fill-opacity="0.7" />
		</svg>
	);
};

SettingIcon.propTypes = {
	className: PropTypes.string,
};

SettingIcon.defaultProps = {
	className: "",
};

export default SettingIcon;
