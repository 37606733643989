const constants = {
  DATA_SOURCE: "datasource",
  TEST_CASE: "testcase",
  ORACLE_SCRIPT: "oscript",
};

export const domainMessage = [
];

export default {
  constants,
};
