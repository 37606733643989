import React from "react";
import PropTypes from "prop-types";

const BarsIcon = ({ className }) => {
	return (
		<svg className={className} width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.50004 2.33333H22.5C22.8095 2.33333 23.1062 2.21042 23.325 1.99162C23.5438 1.77283 23.6667 1.47609 23.6667 1.16667C23.6667 0.857247 23.5438 0.560501 23.325 0.341709C23.1062 0.122917 22.8095 0 22.5 0H1.50004C1.19062 0 0.893875 0.122917 0.675083 0.341709C0.45629 0.560501 0.333374 0.857247 0.333374 1.16667C0.333374 1.47609 0.45629 1.77283 0.675083 1.99162C0.893875 2.21042 1.19062 2.33333 1.50004 2.33333ZM22.5 11.6667H1.50004C1.19062 11.6667 0.893875 11.7896 0.675083 12.0084C0.45629 12.2272 0.333374 12.5239 0.333374 12.8333C0.333374 13.1428 0.45629 13.4395 0.675083 13.6583C0.893875 13.8771 1.19062 14 1.50004 14H22.5C22.8095 14 23.1062 13.8771 23.325 13.6583C23.5438 13.4395 23.6667 13.1428 23.6667 12.8333C23.6667 12.5239 23.5438 12.2272 23.325 12.0084C23.1062 11.7896 22.8095 11.6667 22.5 11.6667ZM22.5 5.83333H1.50004C1.19062 5.83333 0.893875 5.95625 0.675083 6.17504C0.45629 6.39383 0.333374 6.69058 0.333374 7C0.333374 7.30942 0.45629 7.60616 0.675083 7.82496C0.893875 8.04375 1.19062 8.16667 1.50004 8.16667H22.5C22.8095 8.16667 23.1062 8.04375 23.325 7.82496C23.5438 7.60616 23.6667 7.30942 23.6667 7C23.6667 6.69058 23.5438 6.39383 23.325 6.17504C23.1062 5.95625 22.8095 5.83333 22.5 5.83333Z" fill='currentColor' />
		</svg>
	);
};

BarsIcon.propTypes = {
	className: PropTypes.string,
};

BarsIcon.defaultProps = {
	className: "",
};

export default BarsIcon;
