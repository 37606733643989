export const pagePaths = {
    CREATE_WALLET: "/create-wallet",
    SIGNIN: "/signin",
    IMPORT_WALLET_WITH_MNEMONICS: "/import-wallet-with-mnemonics",
    IMPORT_WALLET_WITH_ENCRYPTED_MNEMONICS: "/import-wallet-with-encrypted-mnemonics",
    IMPORT_WALLET_WITH_PRIVATE_KEY: "/import-wallet-with-private-key",
    TX: "/tx",
    TRANSACTION: "/transaction",
    CONFIRM_TX: "/confirm-transaction",
    SEND_TOKENS: "/send-tokens",
    AI_REQUEST_SET: "/ai_request/set",
    AI_REQUEST_GET: "/ai_request/get",
    HOME: "/",
    AUTH: "/auth",
    SECURITY: "/security",
}