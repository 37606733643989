import React from "react";
import PropTypes from "prop-types";

const ExpandFromCornerIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.3335 7.00016H1.00016C0.823352 7.00016 0.653782 7.0704 0.528758 7.19543C0.403734 7.32045 0.333496 7.49002 0.333496 7.66683V13.0002C0.333496 13.177 0.403734 13.3465 0.528758 13.4716C0.653782 13.5966 0.823352 13.6668 1.00016 13.6668H6.3335C6.51031 13.6668 6.67988 13.5966 6.8049 13.4716C6.92993 13.3465 7.00016 13.177 7.00016 13.0002V7.66683C7.00016 7.49002 6.92993 7.32045 6.8049 7.19543C6.67988 7.0704 6.51031 7.00016 6.3335 7.00016ZM5.66683 12.3335H1.66683V8.3335H5.66683V12.3335ZM13.6135 0.746829C13.5458 0.58393 13.4164 0.454479 13.2535 0.386829C13.1733 0.352669 13.0873 0.334549 13.0002 0.333496H9.00016C8.82335 0.333496 8.65378 0.403734 8.52876 0.528758C8.40374 0.653782 8.3335 0.823352 8.3335 1.00016C8.3335 1.17697 8.40374 1.34654 8.52876 1.47157C8.65378 1.59659 8.82335 1.66683 9.00016 1.66683H11.3935L7.86016 5.1935C7.79768 5.25547 7.74808 5.32921 7.71424 5.41045C7.68039 5.49168 7.66296 5.57882 7.66296 5.66683C7.66296 5.75484 7.68039 5.84197 7.71424 5.92321C7.74808 6.00445 7.79768 6.07819 7.86016 6.14016C7.92214 6.20265 7.99587 6.25224 8.07711 6.28609C8.15835 6.31994 8.24549 6.33736 8.3335 6.33736C8.4215 6.33736 8.50864 6.31994 8.58988 6.28609C8.67112 6.25224 8.74485 6.20265 8.80683 6.14016L12.3335 2.60683V5.00016C12.3335 5.17697 12.4037 5.34654 12.5288 5.47157C12.6538 5.59659 12.8234 5.66683 13.0002 5.66683C13.177 5.66683 13.3465 5.59659 13.4716 5.47157C13.5966 5.34654 13.6668 5.17697 13.6668 5.00016V1.00016C13.6658 0.913045 13.6477 0.826978 13.6135 0.746829Z"
                fill="currentColor"
            />
        </svg>
    );
};

ExpandFromCornerIcon.propTypes = {
    className: PropTypes.string,
};

ExpandFromCornerIcon.defaultProps = {
    className: "",
};

export default ExpandFromCornerIcon;
